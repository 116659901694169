<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Working Shifts</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Working Shifts</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_MANAGE_WORKING_SHIFTS')"
                        :to="{name: 'create-working-shift'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Working Shift
                    </router-link>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                responsive
            >
                <template v-slot:cell(start_time)="row">
                    {{convertTimeFormat(row.item.start_time)}}
                </template>
                <template v-slot:cell(end_time)="row">
                    {{convertTimeFormat(row.item.end_time)}}
                </template>
                <template v-slot:cell(night_shift)="row">
                    <span
                            v-if="row.item.night_shift == 1"
                            class="mr-1"
                    >True</span>
                    <span
                            v-if="row.item.night_shift == 0"
                            class="danger mr-1"
                    >False</span>
                </template>
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <router-link
                            class="ml-1 circle"
                            v-b-tooltip.hover title="Edit"
                            v-if="hasPermission('CAN_MANAGE_WORKING_SHIFTS')"
                            :to="{ name: 'edit-working-shift', params: { id:  row.item.id}}"
                        >
                            <font-awesome-icon
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>
                    </span>
                </template>
            </b-table>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            fields: [
                { key: "name",
                    formatter: value => {
                        return value != null
                            ? value.charAt(0).toUpperCase() + value.slice(1)
                            : "";
                    }
                },
                { key: "start_time"},
                { key: "end_time"},
                { key: "night_shift"},
                { key: "actions", label: "Actions"}
            ],
            items: null,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_WORKING_SHIFTS");
        this.getWorkingShifts();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        convertTimeFormat(time){
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            if (time.length > 1) { 
                time = time.slice (1);  
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12; 
            }
            return time.join('');
        },

        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete <b>'+item.name+'</b>?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        getWorkingShifts: function() {
            var query = this.getAuthHeaders();
            query['params'] = {};
            this.axios
                .get(this.$api.get_working_shifts, query)
                .then(response => {
                    this.items = response.data.data;
                    this.api_error = "";
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>